// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz,wght@0,6..96,400..900;1,6..96,400..900&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&family=Lato:wght@400&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Playfair+Display:wght@400;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap);"]);
// Module
exports.push([module.id, "\n/* You can add global styles to this file, and also import other style files */\nhtml, body {\n    margin: 0;\n    padding: 0;\n    width: 100vw;\n    height: 100vh;\n    overflow-x: hidden;\n}\n", "",{"version":3,"sources":["styles.css"],"names":[],"mappings":";AAAA,8EAA8E;AAM9E;IACI,SAAS;IACT,UAAU;IACV,YAAY;IACZ,aAAa;IACb,kBAAkB;AACtB","file":"styles.css","sourcesContent":["/* You can add global styles to this file, and also import other style files */\n@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz,wght@0,6..96,400..900;1,6..96,400..900&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&family=Lato:wght@400&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Playfair+Display:wght@400;700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');\n\nhtml, body {\n    margin: 0;\n    padding: 0;\n    width: 100vw;\n    height: 100vh;\n    overflow-x: hidden;\n}\n"]}]);
// Exports
module.exports = exports;
